@import "../../../App.scss";

.titleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: calc(#{$heightHeader} + 8rem);
    padding-bottom: 6rem;
    @include mobile() {
        text-align: center;
        padding-top: calc(#{$heightHeader} + 4rem);
        padding-bottom: 0.8rem;
    }

    &__title {
        height: 89px;
        margin-bottom: 60px;
        @include mobile() {
            height: 100%;
            margin-bottom: 4rem;
        }
    }

    &__desc {
       max-width: 940px;
       margin: 0 auto;
       text-align: center;
        @include mobile() {
            text-align: center;
        }
    }

    &__primary {
        .titleComponent__title {
            color: $mainColor;
        }

        .titleComponent__desc {
            color: $mainColor;
        }
    }
}