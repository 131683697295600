@import '../../App.scss';

.contact {
    height: 100%;
    @include tablet {
        padding-top: 4rem;
    }

    &__page {
        min-height: 100vh;
        background: $mainColor;
        margin-top: $heightHeader;
        border-top: 1px solid rgba(226, 231, 241, .5);
        & .contact__left__container{
            height: 100vh;
            @include mobile{
                height: 100%;
            }
        }
    }

    &__left {
        &__container {
            height: 100%;
            padding: 6rem 1rem 4rem 4.4rem;
            border-right: 1px solid rgba(226, 231, 241, .5);

            @include desktop {
                padding: 4.4rem;
            }

            @include tablet {
                padding: 2rem;
            }

            @include mobile {
                padding: 4rem 1.6rem;
            }
        }
    }

    &__right {
        &__container {
            padding: 6rem 4.4rem 4rem 1rem;

            &--mobile {
                margin-top: 6rem;
            }

            &--page {
                margin-top: 3rem;
            }

            @include desktop {
                padding: 4.4rem;
            }

            @include tablet {
                padding: 0;

                & .form-contact {
                    margin-top: 0;

                    &__container {
                        padding-inline: 8.4rem;
                        @include tablet{
                            padding-top: 7rem;
                        }
                    }

                    &__label {
                        font-size: 1.4rem;
                    }

                    &__input {
                        padding: 1rem;

                    }
                }
            }

            @include mobile {
                padding: 0;

                & .form-contact {
                    margin-top: 0;

                    &__container {
                        padding-inline: 0rem;
                    }

                    &__label {
                        font-size: 1.6rem;
                        margin-bottom: 2rem;
                    }

                    &__input {
                        padding: 2rem;
                        font-size: 2.4rem;
                        margin-bottom: 2rem;
                    }
                }
            }

            @include mobile {
                padding: 0;
            }
        }
    }

    &__recognize {
        @include mobile {
            margin-top: 1rem;
        }
    }

    &__title {
       

        color: $textColor;
        font-family: $ff-primary;
        font-size: 7.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: .2em;
        &--page {
                margin-bottom: 3rem;
                font-size: 10.2rem;
            }

        @include desktop {
            font-size: 6.2rem;
        }

        @include tablet {
            font-size: 5.2rem;
        }

        @include mobile {
            font-size: 6rem;
            margin-bottom: 4rem;
        }
    }

    &__subtitle {
        color: $textColor;
        @include body1(400);
        margin-bottom: 3rem;
        &--page{
            font-size: 1.6rem;
        }

        @include tablet {
            font-size: 2rem;
        }

        @include mobile {
            margin-bottom: 4rem;
        }
    }

    &__info {
        &__container {
            margin-bottom: 3rem;

            @include desktop {
                margin-bottom: 2rem;
            }

            @include tablet {
                margin-bottom: 2rem;
            }

            @include mobile {
                margin-bottom: 4.4rem;
            }
        }

        &__label {
            color: $textColor;
            @include body1(400);
            opacity: .7;

            @include tablet {
                font-size: 1.4rem;
            }

            @include mobile {
                font-size: 1.6rem;
            }
        }

        &__content {
            &--phone{
                font-size: 1.8rem;
            }
            &--link {
                transition: .4s;

                &:hover {
                    color: $primaryColor;
                }

            }

            color: $textColor;
            @include body1(400);
            margin-top: 1rem;

            @include tablet {
                font-size: 1.4rem;
            }

            @include mobile {
                font-size: 1.6rem;
            }
        }
    }

    &__social {
        &__container {
            display: flex;

            & img {
                @include mobile {
                    margin-left: 1.2rem;
                }
            }
        }

        &__label {
            color: $textColor;
            @include body1(400);
            opacity: .7;

            @include tablet {
                font-size: 1.4rem;
            }
        }

        &__icon {
            &__container {
                @include flex(unset, center);
            }

            cursor: pointer;
            @include flex(center, center);
            height: 4rem;
            width: 4rem;
            border-radius: 50%;
            background-color: $textColor;
            margin-left: 4rem;
            position: relative;
            overflow: hidden;

            @include desktop {
                margin-left: 2rem;
                height: 3rem;
                width: 3rem;
            }

            @include tablet {
                margin-left: 2rem;
                height: 3rem;
                width: 3rem;
            }

            @include mobile {
                margin-left: 1.2rem;
                height: 4rem;
                width: 4rem;
            }

            &::after {
                content: "";
                position: absolute;
                top: -4rem;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(-45deg, #fc6767, #ec008c);
                transition: .5s;
            }

            &:hover::after {
                top: 0;
            }

            &:hover svg,
            &:hover svg g {
                fill: $textColor;
            }

            & svg {
                fill: $mainColor;
                height: 1.8rem;
                transition: .6s;
                z-index: 10;

                @include desktop {
                    height: 1.3rem;
                }
            }

            &--zalo svg {
                width: 2.2rem;
            }
        }
    }


}