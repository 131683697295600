@import '../../App.scss';

.job {
    margin-left: 6rem;
    height: 100%;

    &__pagination{
        @include mobile{
            margin-top: 2rem;
        };
    }

    &__commingsoon{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        & h5{
            font-size: 6rem;
            color: $primaryColor;
            font-weight: bold;
        }
    }

    @include mobile{
        margin-left: 0rem;
        margin-bottom: 6rem;
    }
    
    @include desktop{
        margin-left: 4.4rem;
    }

    &__pagination {
        & .ant-pagination-item-active a {
            color: $primaryColor;
        }

        & .ant-pagination-item-active:hover {
            border-color: $primaryColor;
        }

        & .ant-pagination-item-active {
            border-color: $primaryColor;
        }

        & .ant-pagination-item:hover {
            border-color: $primaryColor;
        }

        & .ant-pagination-item:hover a {
            color: $primaryColor;
        }

        & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
        .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
            color: $primaryColor;
        }

        & .ant-pagination-prev:hover .ant-pagination-item-link,
        .ant-pagination-next:hover .ant-pagination-item-link {
            color: $primaryColor;
            border-color: $primaryColor;
        }
    }

    &__result {
        @include body1(400);
        @include mobile{
            display: none;
        }
    }

    &__list {
        margin-top: 3rem;
        min-height: 80%;
        @include tablet{
            margin-bottom: 1.5rem;
        }
        @include mobile{
            margin-top: 0;
        }
    }

    &__item {
        width: 100%;
        max-height: 11.2rem;
        height: 100%;
        padding: 2rem;
        border: 1px solid $borderColor;
        transition: .4s linear;
        margin-bottom: 2rem;
        &:hover{
            border-color: $primaryColor;
        }

        @include mobile{
            max-height: 13.8rem;
        }

        &__container{
            @include mobile{
                margin-left: 2rem;
            }
        }
    }

    &__item {
        cursor: pointer;
        &:hover{
            // svg path{
            //     fill: #ffd634;
            //     transition: all .5s ease-in-out;
            // }
        }
        &__logo {
            width: 7.2rem;
            height: 7.2rem;
            padding: 0rem 1rem;
            border-radius: 4px;
            border: 1px solid $borderColor;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                height: 1.4rem;
                width: 100%;
                object-fit: contain;
            }
        }

        &__title {
            color: #000;
            /* Title Bold */
            font-family: $ff-secondary;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
        }

        &__description {
            display: flex;
            align-items: center;
            margin-top: 1.2rem;
            @include mobile{
                flex-wrap: wrap ;
            }

            &__container {
                padding: 0 2.3rem 0 2rem;
                @include mobile {
                        padding: 0;
                        width: 50%;
                        &:nth-child(2){
                            padding-left: 1rem;
                            border-right: none;
                        }
                    }

                &:first-child {
                    padding-left: 0;
                }


                & svg {
                    height: 1.2rem;
                    width: 1.2rem;
                    margin-right: 1rem;
                }
                & span{
                    @include mobile{
                        font-size: 1.2rem;
                    }
                }

                &--divider {
                    border-right: 1px solid $borderColor;
                    border-left: 1px solid $borderColor;
                }
            }

        }
    }
}