@import "../../../App.scss";

.home-section {
    padding-inline: $pd-x-default;
    padding-top: 7.6rem;
    height: 100vh;
    width: 100%;

    @include mobile {
        padding-inline: $pd-mb-x-default;
        padding-top: 6rem;
        height: auto;
    }

    @include tablet {
        padding-inline: $pd-tl-x-default;
        padding-top: 8rem;
    }
}

.section {
    &__container {
        height: 100vh;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }


    &__wrapper {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) 2.5%, rgba(0, 0, 0, 0.00) 77%);
        height: 100%;
        width: 100%;
    }

    &__row {
        padding-top: 11.2rem;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        width: 100%;
    }

    &__content {
        &__container {
            height: 50%;
        }

        &__divider {
            width: 416px;
            height: 1px;
            opacity: 0.5;
            background: $borderColor;

            @include mobile {
                width: 200px;
            }
        }

        &__row {
            display: flex;
            padding-inline: $pd-x-default;
            height: 100%;

            @include mobile {
                padding-inline: $pd-mb-x-default;
                flex-direction: column;
            }
        }

        &__left {
            width: 50%;
            padding-top: 4rem;
            display: flex;
            flex-direction: column;

            &__subtitle {
                @include body2();
                color: $textColor;
                margin-bottom: .6rem;
            }

            &__title {
                @include body1(700);
                color: $textColor;
            }
        }

        &__right {
            width: 50%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @include mobile {
                width: 100%;
            }
        }


    }
}

.sectionTwo {
    &__list {
        height: 100%;
        width: 100%;
        display: flex;
        padding-top: 4rem;
        padding-bottom: 8rem;

        @include custom-breakpoint(1200px, 1299px) {
            padding-bottom: 2rem;
        }

        @include tablet {
            padding-top: 2rem;
            padding-bottom: 4rem;
            flex-direction: column;
        }

        @include mobile {
            flex-direction: column;
            padding-top: 0rem;
            padding-bottom: 4rem;
        }
    }

    &__item {
        flex: 1;
        width: 100%;
        height: 100%;

        &--two {
            margin-left: 3.9rem;
            margin-right: 2.4rem;

            @include mobile {
                margin-left: 0;
                margin-right: 0;
                flex: 2;
                margin-top: 2rem;
            }

            @include tablet {
                margin-left: 0;
                margin-right: 0;
                flex: 1;
                margin-top: 5rem;
            }
        }

        &__nextButton {
            display: flex;
            justify-content: flex-end;

            @include mobile {
                flex-direction: column;
            }

            @include tablet {
                flex-direction: column;
            }
        }

        &--three {
            padding-left: 3rem;
            display: flex;
            flex-direction: column-reverse;

            @include mobile {
                position: absolute;
                padding-left: 0;
                flex-direction: row;
                top: 141%;
                height: 50%;
                padding-right: 3rem;
            }

            @include tablet {
                position: absolute;
                padding-left: 0;
                flex-direction: row;
                top: 141%;
                height: 50%;
                padding-right: 5rem;
            }

            &>span {
                color: $textColor;
                @include body1(400);
                opacity: .6;
                margin-top: 8rem;
                overflow: hidden;

                @include tablet {
                    margin-top: 9rem;
                }
            }
        }

        &__image {
            background: lightgray 50% / cover no-repeat, #D9D9D9;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &--first {
                max-width: 47.4rem;
                height: 34.8rem;
                margin-right: 3rem;
                margin-top: 4rem;
                max-height: 100%;

                @include custom-breakpoint(1200px, 1299px) {
                    height: 23.8rem;
                }

                @include mobile {
                    margin-right: 0;
                    margin-top: 0;
                    height: 14.8rem;
                }

                @include tablet {
                    margin-right: 0;
                    margin-top: 0;
                    // height: 14.8rem;
                    height: 100%;
                    max-width: 100%;
                }
            }

            &--two {
                max-width: 48.9rem;
                height: 73.6rem;
                max-height: 100%;

                @include mobile {
                    opacity: .5;
                }

                @include tablet {
                    opacity: .5;
                    max-width: 100%;
                }
            }
        }

        &__divider {
            height: 100%;
            width: .1rem;
            opacity: 0.5;
            background: $borderColor;

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &__divider__bottom {
            width: 41.6rem;
            height: .1rem;
            margin-top: 3rem;
            opacity: 0.5;
            background: $borderColor;
            transform: translateX(-$pd-x-default);

            @include tablet {
                width: 100%;
                margin-inline: 2rem;
                transform: translateX(-2rem);
            }

            @include mobile {
                width: 100%;
                margin-inline: $pd-mb-x-default;
                transform: translateX(-$pd-mb-x-default);
                margin-top: 2rem;
            }
        }

        &__title {
            padding-top: 4rem;
            position: relative;
            z-index: 1;
            transform: translateX(6rem);
            opacity: 0;
            transition: all .3s ease;

            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            &>span {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                color: #FFF;
                font-family: VL Bebas Neue;
                font-size: 10.2rem;
                font-style: normal;
                font-weight: 700;
                width: 636px;

                @include desktop {
                    font-size: 7.2rem;
                }

                @include mobile {
                    font-size: 43px;
                    top: -60px;
                }

                @include tablet {
                    font-size: 5.2rem;
                    top: -90px;
                }
            }
            &--bottom{
                top: 11rem !important;
            }
        }
    }
}

.sectionTwoMobile {
    &__list {
        height: 100%;
        width: 100%;
        display: flex;
    }

    &__text {
        margin-top: 4rem;
        color: $textColor;
        /* Body 1 */
        font-family: $ff-secondary;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
        opacity: .7;
    }

    &__item {
        flex: 1;
        width: 100%;
        height: 100%;

        &--two {
            margin-left: 0rem;
            margin-right: 0rem;
            margin-top: 2rem;
        }

        &__nextButton {
            display: flex;
            justify-content: flex-end;


        }

        &--three {
            // display: flex;
            // flex-direction: column-reverse;



            &>span {
                color: $textColor;
                @include body1(400);
                opacity: .6;
                margin-top: 8rem;
                overflow: hidden;


            }
        }

        &__image {
            background: lightgray 50% / cover no-repeat, #D9D9D9;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &--first {
                max-width: 47.4rem;
                height: 28rem;
                margin-right: 0rem;
                margin-top: 4rem;
                max-height: 100%;


            }

            &--two {
                max-width: 48.9rem;
                height: 52rem;
                max-height: 100%;


            }
        }

        &__divider {
            height: 100%;
            width: .1rem;
            opacity: 0.5;
            background: $borderColor;


        }

        &__divider__bottom {
            width: 41.6rem;
            height: .1rem;
            margin-top: 3rem;
            opacity: 0;
            background: $borderColor;
            transform: translateX(-$pd-x-default);
            @include mobile{
                margin-top: 0;
            }


        }

        &__title {
            position: relative;
            width: 20.2rem;


            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            &>span {
                color: $textColor;
                font-family: VL Bebas Neue;
                font-size: 6rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 72px */

            }
        }
    }
}

.sectionThree {

    &__container {
        height: 100%;
        width: 100%;
        padding-bottom: 8rem;
        padding-top: 4rem;
        display: flex;

        @include mobile {
            padding-bottom: 4rem;
            padding-top: 0rem;
        }

        @include tablet {
            padding-bottom: 4rem;
            padding-top: 0rem;
        }
    }

    &__left {
        // width: 103.2rem;
        width: 100%;
        padding-right: 2.4rem;

        @include mobile {
            padding-right: 0rem;
            height: 70%;
        }

        @include tablet {
            padding-right: 0rem;
            height: 70%;
        }

        &__image {
            width: 100%;
            height: 100%;
            background: lightgray 50% / cover no-repeat, #D9D9D9;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @include custom-breakpoint(1200px, 1299px) {
                    height: 88%;
                }
        }
    }

    &__right {
        flex: 1;
        padding-left: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include mobile {
            margin-top: 1rem;
            padding-left: 0;
            flex-direction: row-reverse;

            & .sectionTwo__item__nextButton {
                justify-content: unset;
            }
        }

        @include tablet {
            padding-left: 0;
            flex-direction: row-reverse;

            & .sectionTwo__item__nextButton {
                justify-content: unset;
            }
        }


        &__title {
            color: $textColor;
            font-family: VL Bebas Neue;
            font-size: 10.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            z-index: 1;
            transform: translateX(-6rem);
            opacity: 0;
            transition: all .4s ease;

            @include desktop {
                font-size: 7.2rem;
            }

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }

            &--mobile {
                display: none;

                @include mobile {
                    position: absolute;
                    top: -80%;
                    left: 0;
                    padding-left: 26px;
                    display: block;
                    color: $textColor;
                    font-family: $ff-primary;
                    font-size: 43px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    /* 51.6px */
                }

                @include tablet {
                    position: absolute;
                    top: -170%;
                    left: 0;
                    padding-left: 26px;
                    display: block;
                    color: $textColor;
                    font-family: $ff-primary;
                    font-size: 5.2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    /* 51.6px */
                }
            }


            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            /* 122.4px */
        }

        &__content {
            color: $textColor;
            @include body1(400);
            opacity: .6;

            @include mobile {
                font-size: 16px;
            }

            @include tablet {
                font-size: 16px;
                margin-right: 1rem;
            }

            @include desktop {
                display: none;
            }
        }
    }
}

.sectionThreeMobile {

    &__container {
        height: 100%;
        width: 100%;
        display: flex;


    }

    &__left {
        // width: 103.2rem;
        width: 100%;
        height: 30rem;



        &__image {
            width: 100%;
            height: 100%;
            background: lightgray 50% / cover no-repeat, #D9D9D9;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    &__right {
        flex: 1;
        padding-left: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__title {
            color: $textColor;
            font-family: $ff-primary;
            font-size: 6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 72px */


            &--mobile {
                display: none;




            }


            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            /* 122.4px */
        }

        &__content {
            color: $textColor;
            @include body1(400);
            opacity: .6;
            margin-block: 4rem;


        }
    }
}

.sectionFour {

    &__container {
        height: 100%;
        width: 100%;
        padding-bottom: 8rem;
        padding-top: 4rem;
        @include custom-breakpoint(1200px, 1299px) {
                        padding-bottom: 4rem;
            }

        @include mobile {
            padding-bottom: 4rem;
            padding-top: 0rem;
        }

        @include tablet {
            padding-bottom: 4rem;
            padding-top: 0rem;
        }
    }

    &__left {
        // width: 103.2rem;
        width: 100%;
        padding-right: 2.4rem;

        &__image {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @include desktop {
                background-size: cover;
            }

            @include tablet {
                background-size: cover;
            }
        }

        &__title {
            color: $textColor;
            font-family: VL Bebas Neue;
            font-size: 10.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            display: block;
            z-index: 1;
            transform: translateX(6rem);
            opacity: 0;
            transition: all .3s ease;

            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }

            @include desktop {
                font-size: 7.2rem;
            }
        }
    }

    &__right {
        flex: 1;
        padding-left: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }



        &__image {
            width: 100%;
            height: 55%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            max-width: 474px;
            max-height: 344px;

            @include mobile {
                height: 30%;
            }

            @include tablet {
                height: 30%;
            }
        }

        &__content {
            color: $textColor;
            @include body1(400);
            opacity: .6;
        }
    }
}

.sectionFourMobile {

    &__container {
        height: 100%;
        width: 100%;
        display: flex;


    }

    &__left {
        // width: 103.2rem;
        width: 100%;
        height: 30rem;

        @include tablet {
            height: 0;
        }



        &__image {
            width: 100%;
            height: 100%;
            background: lightgray 50% / cover no-repeat, #D9D9D9;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    &__right {
        flex: 1;
        padding-left: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__title {
            color: $textColor;
            font-family: $ff-primary;
            font-size: 6rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 72px */


            &--mobile {
                display: none;




            }


            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            /* 122.4px */
        }

        &__content {
            color: $textColor;
            @include body1(400);
            opacity: .6;
            margin-block: 4rem;


        }
    }
}

.sectionFive {
    padding-left: $pd-x-default;
    padding-top: 7.6rem;
    height: 100vh;
    width: 100%;

    @include mobile {
        padding-left: 0;
        padding-top: 3rem;
    }

    @include tablet {
        padding-left: 0;
        padding-top: 3rem;
    }

    &__container {
        padding-top: 4rem;
        height: 100%;

        @include mobile {
            flex-direction: column-reverse;
        }

        @include tablet {
            flex-direction: column-reverse;
        }
    }

    &__left {
        &__container {
            margin-top: 25%;

            @include custom-breakpoint(1200px, 1299px) {
                    margin-top: 0%;
                }

            @include mobile {
                margin-top: 0rem;

                & .sectionTwo__item__nextButton {
                    display: none;
                }
            }

            @include tablet {
                margin-top: 0rem;

                & .sectionTwo__item__nextButton {
                    display: none;
                }
            }
        }

        &__title {
            color: $textColor;
            font-family: Helvetica Neue;
            font-size: 4.3rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;

            @include custom-breakpoint(1200px, 1299px) {
                                font-size: 3.3rem;
                }

            @include mobile {
                font-size: 1.8rem;
            }

            @include tablet {
                font-size: 2rem;
            }

            @include desktop {
                font-size: 3.2rem;
            }
        }

        &__subtitle {
            margin-top: 8.7rem;
            padding-left: 2.6rem;
            color: $textColor;
            @include body1(400);
            border-left: 2px solid $borderColor;
            margin-bottom: 5.4rem;

            @include mobile {
                font-size: 1.6rem;
                margin-top: 3rem;
                padding-left: 2rem;

            }

            @include tablet {
                font-size: 1.6rem;
                margin-top: 2rem;
                padding-left: 2rem;

            }
        }
    }

    &__left {
        &__wrapper {
            height: 100%;

            @include mobile {
                height: 30%;
                padding-inline: $pd-mb-x-default;
                padding-top: 2rem;
            }

            @include tablet {
                height: 30%;
                padding-inline: $pd-tl-x-default;
                padding-top: 2rem;
            }
        }
    }

    &__right {
        &__wrapper {
            height: 100%;

            @include mobile {
                height: 70%;
            }

            @include tablet {
                height: 70%;
            }
        }

        &__container {
            height: 100%;
            width: 100%;
            background: #1E1E1E;
            padding: 8rem 0rem 8rem 8rem;
            margin-left: 6rem;

            @include desktop {
                padding: 6rem 0rem 6rem 6rem;
                margin-left: 4rem;
            }

            @include mobile {
                padding: 3rem 0rem 3rem 3rem;
                margin-left: 0;
                height: 100%;
            }

            @include tablet {
                padding: 3rem 0rem 3rem 3rem;
                margin-left: 0;
                height: 100%;
            }
        }

        &__image {
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}

.sectionFiveMobile {
    padding-left: 0;
    padding-top: 6rem;
    width: 100%;

    &__container {
        height: 100%;


        flex-direction: column-reverse;


    }

    &__left {
        &__container {
            margin-top: 20%;
            margin-top: 0rem;

            & .sectionTwo__item__nextButton {
                display: none;
            }

        }

        &__title {
            color: $textColor;
            font-family: Helvetica Neue;
            font-size: 4.3rem;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
        }

        &__subtitle {

            color: $textColor;
            @include body1(400);
            border-left: 2px solid $borderColor;
            margin-bottom: 6rem;
            margin-top: 4rem;
            padding-left: 2rem;

        }
    }

    &__left {
        &__wrapper {
            // height: 30%; 
            padding-inline: $pd-mb-x-default;
            padding-top: 2rem;


        }
    }

    &__right {
        &__wrapper {
            // height: 70%;


        }

        &__container {
            height: 100%;
            width: 100%;
            background: #1E1E1E;
            padding: 2rem 0rem 2rem 2rem;
            margin-left: 0;
            height: 100%;

        }

        &__image {
            height: 100%;
            width: 100%;
            height: 26.8rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}

.sectionSix {
    background: #000;

    &__dn {
        display: none !important;

        @include desktop {
            display: flex !important;
        }
    }

    padding-left: $pd-x-default;
    height: calc(75% - 7.6rem);
    width: 100%;


    @include mobile {
        height: 100%;
        padding-left: 0;
    }

    @include tablet {
        height: 100%;
        padding-left: $pd-tl-x-default;
    }

    &__container {
        padding-top: 8rem;
        height: 100%;
        flex-direction: row-reverse;

        @include mobile {
            padding-top: 6rem;
            flex-direction: column-reverse;
        }

        @include tablet {
            padding-top: 2rem;
        }

        @include desktop {
            padding-top: 4rem;
        }

    }

    &__partner {
        &.partner {
            margin-top: $heightHeader;
        }

        background-color: #fff;
        height: 25%;
        margin-top: 2rem;

        @include mobile {
            height: 10%;
        }

        @include tablet {
            height: 15%;
        }

        @include desktop {
            height: 20%;
        }

        &__icon {

            & svg,
            img {
                width: auto;
                height: 4.2rem;

                @include mobile {
                    height: 3.2rem;
                }

                @include tablet {
                    height: 1rem;
                }

                @include desktop {
                    height: 2rem;
                }
            }

            &__container {
                margin-top: 3.1rem;

                @include custom-breakpoint(1200px, 1299px) {
                        margin-top: 2rem;
                    }

                @include mobile {
                    margin-top: 4rem;
                }

                @include tablet {
                    margin-top: .5rem;
                }

                @include desktop {
                    margin-top: 1.2rem;
                }
            }
        }

        &__container {
            height: 100%;
            padding: 6rem 0 6rem 18.3rem;

            @include mobile {
                padding: 4rem 0 4rem 1.6rem;
            }

            @include tablet {
                padding: 2rem 3.2rem;
            }

            @include desktop {
                padding: 4rem 0 4rem 16.3rem
            }
        }

        &__divider {
            height: 1px;
            width: 100%;
            background-color: rgba(226, 231, 241, 1);

            &__wrapper {
                display: flex;
                flex-direction: column-reverse;
                padding-bottom: .4rem;
            }

            &__container {
                & span {
                    color: #000;
                    font-family: $ff-secondary;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;

                    @include custom-breakpoint(1200px, 1299px) {
                            font-size: 1.2rem;
                        }

                    /* 19.2px */
                    @include mobile {
                        // font-size: .6rem;
                    }

                    @include tablet {
                        font-size: .6rem;
                    }
                }
            }
        }
    }

    &__left {
        &__wrapper {
            height: 100%;

            @include mobile {
                height: 45%;
                // padding-inline: $pd-mb-x-default;
                // padding-top: 2rem;
            }

            @include tablet {
                // height: 45%;
                // padding-inline: $pd-mb-x-default;
                padding-top: 2rem;
                flex: 1
            }
        }

        &__button {
            padding-right: $pd-mb-x-default;

            @include desktop {
                padding-right: 0;
                margin: auto
            }

            @include mobile {
                @include flex(center, center);
                margin-bottom: 14rem;
            }
        }

        &__container {
            // margin-top: 20%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 8rem;

            @include mobile {
                padding-bottom: 0rem;
                justify-content: unset;
                padding-left: $pd-mb-x-default;
            }

            @include tablet {
                padding-bottom: 0rem;
                justify-content: unset;
            }
        }

        &__nameProduct {
            color: $textColor;
            font-family: $ff-secondary;
            font-size: 2.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            /* 24px */
            text-transform: uppercase;
            margin-bottom: 1rem;

            @include mobile {
                // font-size: 1.6rem;
            }

            @include tablet {
                font-size: 1.6rem;
            }
        }

        &__contact {
            color: $textColor;
            font-family: $ff-secondary;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 16px */
            text-transform: capitalize;

            // @include mobile {
            //     font-size: 1.2rem;
            // }

            @include tablet {
                font-size: 1.2rem;
            }
        }

        &__title {
            color: $textColor;
            font-family: $ff-primary;

            font-size: 10.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;

            /* 122.4px */
            @include mobile {
                font-size: 6rem;
                width: 14.3rem;
                margin-bottom: 6rem;
            }

            @include tablet {
                font-size: 5.2rem;
            }

            @include desktop {
                font-size: 7.2rem;
            }
        }

        &__subtitle {
            margin-top: 8.7rem;
            padding-left: 2.6rem;
            color: $textColor;
            @include body1(400);
            border-left: 2px solid $borderColor;
            margin-bottom: 5.4rem;
        }
    }

    &__right {
        &__wrapper {
            height: 100%;

            @include mobile {
                height: 55%;
            }

            @include tablet {
                height: 40%;
            }
        }

        &__container {
            height: 100%;
            width: 100%;
            background: #1E1E1E;
            margin-left: 6rem;

            @include mobile {
                margin-left: 0;
            }

            @include tablet {
                margin-left: 0;
            }

            @include desktop {
                margin-left: 4rem;
            }
        }

        &__image {
            &.partner {
                height: 464px;
            }

            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            @include mobile {
                height: 23.2rem;
            }
        }
    }
}


.sectionFourMobile {
    &__list {
        height: 100%;
        width: 100%;
        display: flex;
        padding-top: 4rem;
        padding-bottom: 8rem;

        @include mobile {
            flex-direction: column;
            padding-top: 0rem;
            padding-bottom: 4rem;
        }

        @include tablet {
            flex-direction: column;
            padding-top: 0rem;
            padding-bottom: 4rem;
        }
    }

    &__item {
        flex: 1;
        width: 100%;
        height: 100%;


        &--two {
            margin-left: 3.9rem;
            margin-right: 2.4rem;

            @include mobile {
                margin-left: 0;
                margin-right: 0;
                flex: 2;
                margin-top: 5rem;
            }

            @include tablet {
                margin-left: 0;
                margin-right: 0;
                flex: 1;
                margin-top: 5rem;

            }
        }

        &__nextButton {
            display: flex;
            justify-content: flex-end;

            @include mobile {
                flex-direction: column;
            }

            @include tablet {
                flex-direction: column;
            }
        }

        &--three {
            padding-left: 3rem;
            display: flex;
            flex-direction: column-reverse;

            @include mobile {
                position: absolute;
                padding-left: 0;
                flex-direction: row;
                top: 40%;
                height: 50%;
                padding-right: 3rem;

                @include mobile {
                    padding-right: 0;
                }

                @include tablet {
                    padding-right: 0;
                }
            }

            @include tablet {
                position: absolute;
                padding-left: 0;
                flex-direction: row;
                top: 40%;
                height: 50%;
                padding-right: 3rem;


                padding-right: 0;

            }

            &>span {
                color: $textColor;
                @include body1(400);
                opacity: .6;
                margin-top: 8rem;

                @include mobile {
                    margin-top: 0;
                }

                @include tablet {
                    margin-top: 7.7rem;
                }
            }
        }

        &__image {
            background: lightgray 50% / cover no-repeat, #D9D9D9;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &--first {
                max-width: 47.4rem;
                height: 34.8rem;
                margin-right: 3rem;
                margin-top: 4rem;
                max-height: 100%;

                @include mobile {
                    margin-right: 0;
                    margin-top: 0;
                    height: 14.8rem;
                }

                @include tablet {
                    margin-right: 0;
                    margin-top: 0;
                    // height: 14.8rem;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            &--two {
                max-width: 48.9rem;
                height: 73.6rem;
                max-height: 100%;

                @include mobile {
                    opacity: .5;
                }

                @include tablet {
                    opacity: .5;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        &__divider {
            height: 100%;
            width: .1rem;
            opacity: 0.5;
            background: $borderColor;

            @include mobile {
                display: none;
            }

            @include tablet {
                display: none;
            }
        }

        &__divider__bottom {
            width: 41.6rem;
            height: .1rem;
            margin-top: 3rem;
            opacity: 0.5;
            background: $borderColor;
            transform: translateX(-$pd-x-default);

            @include mobile {
                width: 100%;
                transform: translateX(0);
            }

            @include tablet {
                width: 100%;
                transform: translateX(0);
            }
        }

        &__title {
            padding-top: 4rem;
            position: relative;
            z-index: 1;
            transform: translateX(6rem);
            opacity: 0;
            transition: all .3s ease;

            &.animate {
                transform: translateX(0);
                opacity: 1;
            }

            &>span {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                color: #FFF;
                font-family: VL Bebas Neue;
                font-size: 10.2rem;
                font-style: normal;
                font-weight: 700;
                width: 636px;

                @include mobile {
                    font-size: 43px;
                    top: -70px;
                }

                @include tablet {
                    font-size: 43px;
                    top: -70px;
                }
            }
        }
    }
}

.sectionSeven {
    display: flex;
    flex-direction: column;
    height: calc(100% - $heightHeader);
    border-top: 1px solid rgba(226, 231, 241, .5);

    &__wrapper {
        flex: 1;
    }
}