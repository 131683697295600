@import '../../../App.scss';

.nextButton {
    @include flex(center, center);
    width: 100px;
    height: 100px;

    @include mobile {
        width: 5.6rem;
        height: 5.6rem;

        & svg {
            width: 2rem;
            height: 2rem;
        }
    }


    border-radius: 10rem;
    border: .1rem solid $borderColor;
    background: rgba(0, 0, 0, 0.30);

    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
    outline: 1px solid;
    outline-color: rgba(255, 255, 255, .5);
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
        cursor: pointer;
        border: 1px solid;
        box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
        outline-color: rgba(255, 255, 255, 0);
        outline-offset: 15px;
        text-shadow: 1px 1px 2px #427388;
        opacity: .6;
    }

    &__up{
        & svg{
            transform: rotate(-90deg);
        }
    }
}