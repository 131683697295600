@import "../../App.scss";

.HRMPage {
    .tabHRM {
        .ant-tabs {
            &-ink-bar {
                background: $primaryColor;
                height: 4px;
                border-radius: 2px;

            }

            &-nav {
                margin-inline: 17.6rem;
                margin-bottom: 8rem;

                @include tablet() {
                    margin-inline: 2.6rem;
                    margin-bottom: 4rem;
                }

                @include desktop() {
                    margin-inline: 4.4rem;
                    margin-bottom: 5rem;
                }

                @include mobile() {
                    margin-inline: 0;
                    margin-bottom: 3rem;
                }


                &::before {
                    border-bottom: none;
                }

                &-list {
                    width: 100%;
                    border-bottom: 1px solid rgba(226, 231, 241, 0.5);

                    // margin: 0 176px;
                    @include mobile() {
                        margin: 0 1.6rem;
                    }
                }
            }

            &-tab {
                width: 432px;
                justify-content: center;
                margin: 0;
                padding: 22px 0;

                @include mobile() {
                    width: 100%;
                    padding: 22px;
                }

                &-btn {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    color: $mainColor;
                    opacity: 0.6;
                }

                &:active {
                    .ant-tabs-tab-btn {
                        color: $primaryColor;

                    }
                }
            }
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $primaryColor;
            font-weight: 700;
            opacity: unset;
        }
    }
}