@import '../../App.scss';

.CollapseFilter {
    &__panel {
        font-weight: 700;
        @include body1(700);

        & .ant-collapse-header {
            padding: 0 !important;
            flex-direction: row-reverse;
        }

        & .ant-collapse-content-box {
            padding: 0rem 0 3rem !important;
        }
    }
}

.Checkbox {
    &__all {
        margin-top: 3rem;

        & .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $primaryColor;
            border-color: $primaryColor;
        }

        & .ant-checkbox-checked::after {
            border-color: $primaryColor;
        }

        & .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: $primaryColor;
        }

        & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner {
            border-color: $primaryColor;
        }
        &:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner{
            border-color: $primaryColor;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;

        & .ant-checkbox-wrapper {
            margin-top: 2rem;
            @include body1(400);
        }

        & .ant-checkbox:hover .ant-checkbox-inner,
        & .ant-checkbox-checked .ant-checkbox:hover .ant-checkbox-inner {
            border-color: $primaryColor;
        }

        & .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $textColor;
            border-color: $primaryColor;

            &:hover {
                border-color: $primaryColor;
            }

            &::after {
                border-color: $primaryColor;
            }
        }

        & .ant-checkbox-checked::after {
            border-color: $primaryColor;
        }

        & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus+.ant-checkbox-inner {
            border-color: $primaryColor;
        }
        
    }
}