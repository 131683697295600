@import "../../../../../App.scss";
.furniture {
    padding: 4.2rem 4.4rem 0 4.4rem;
    @include mobile() {
        padding: 4rem 0 0 0;
    }
    &__items {
        padding-bottom: 6rem;
        .furniture__card {
            .ant-card-body {
                padding: 0;
            }
            .ant-card-meta-detail {
                display: flex;
                padding-top: 2rem;
                gap: 1.1rem;
                flex-direction: column-reverse;
                .ant-card-meta-description {
                    color: $textColor;
                    font-family: Helvetica Neue;
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }
                .ant-card-meta-title {
                    color: $textColor;
                    font-family: Helvetica Neue;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                }
            }
        }
    }
    &__modal {
        width: 100% !important;
        padding: 0 17.6rem;
        @include tablet() {
            padding: 0 1rem;
        }
        @include mobile() {
            padding: 0 0rem;
        }
        img {
            width: 100%;
            height: 100%;
            max-height: 52rem;
            object-fit: cover;
        }
        .ant-modal-body {
            background: black;
            @include mobile() {
                padding: 0;
            }
        }
        &--item {
            background: none;
            @include mobile() {
                padding: 0 1.6rem;
                padding-top: 4.5rem;
            }
            .modal--title {
                color: $textColor;
                font-family: VL Bebas Neue;
                font-size: 43px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 0;
                @include mobile() {
                    margin-top: 4rem;
                }
            }
            .modal--desc {
                opacity: unset;
                font-weight: 700;
                padding: 3rem 0;
                @include tablet() {
                    padding: 2rem 0;
                }
            }
            .modal--text {
                border-bottom: 1px solid $borderColor;
                @include mobile() {
                    padding-bottom: 4rem;
                }
            }
            .modal--content {
                ul {
                    margin: 0;
                    padding-bottom: 4rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    @include tablet() {
                        padding-bottom: 2rem;
                    }
                    @include mobile() {
                        padding-bottom: 0;
                    }

                    li {
                        margin-left: 2rem;
                        list-style-type: disc;
                        color: $textColor;
                        @include tablet() {
                            margin-bottom: 1rem;
                        }
                    }
                }
                &--footer {
                    padding-bottom: 3rem !important;
                }
            }
            .modal--button {
                height: 5.4rem;
                width: 24rem;
                background: none;
                color: $textColor;
                @include tablet() {
                    height: 3.4rem;
                }
                // border-bottom: 1px solid rgba(226, 231, 241, 0.5);
                &:hover {
                    color: $primaryColor;
                    border-color: $primaryColor;
                    font-weight: 700;
                }
                &:active {
                    color: $primaryColor;
                    border-color: $primaryColor;
                }
                &:focus {
                    color: $primaryColor;
                    border-color: $primaryColor;
                }
            }
        }
        .ant-modal-mask {
            background-color: $mainColor !important;
        }
        .ant-modal-close {
            opacity: 0.6;
            color: $textColor;
            svg {
                width: 3.2rem;
                height: 3.2rem;
            }
        }
        .ant-modal-content {
            box-shadow: none;
        }
    }
    .ant-card-meta-detail > div {
        margin-bottom: 0;
    }
    &__card {
        &__img {
            position: relative;
            overflow: hidden;
            &--layout {
                transition: transform 0.8s;
                transform-origin: center center;
                width: 50rem;
                height: 32rem;
                object-fit: cover;
                @include mobile(){
                    width: 41.2rem;
                    height: 26.4rem;
                }
            }
            &:hover {
                .furniture__card__img--layout {
                    transform: scale(1.1);
                }
            }
        }
        &__content {
            @include mobile() {
                padding: 0 1.6rem;
            }
        }
    }
}
.furniture__card:hover .ant-card-meta-title,
.furniture__card:hover .ant-card-meta-description {
  color: red !important; /* Màu khi hover */
}
