@import "../../../../App.scss";
.inforServices {
    &__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        color: $textColor;
        margin-bottom: 10px;
        @include mobile(){
            margin-bottom: 1rem;
        }
    }
    &__subTitle {
        margin-bottom: 40px;
        @include mobile(){
            // margin-bottom: 20px;
        }
    }
    img {
        width: 100%;
    }
    &--img {
        width: 28px;
        height: 28px;
    }
    &__bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include mobile(){

            margin-bottom: 6rem !important;
        }
        &--title {
            padding-top: 30px;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            text-align: center;
            max-width: 1032px;
            margin: 0 auto;
            color: $textColor;
        }
        .imgCustom{
            @include mobile(){
                margin-top: 6rem;
            }
        }
    }
}
