@import '../../../../App.scss';

.footer {
    padding: 4.2rem 17.7rem;

    @include custom-breakpoint(1900px,3000px){
    padding: 6.2rem 17.7rem;
    }

    @include desktop {
        padding: 4.2rem 5rem;
    }

    @include tablet {
        padding: 4.4rem;
    }

    @include mobile {
        padding-inline: 3.2rem;
        border-bottom: 1px solid rgba(226, 231, 241, .5);
    }

    &--home {
        padding-block: 2.2rem;

    }

    border-top: 1px solid rgba(226, 231, 241, .5);
    background: $mainColor;

    &__button{
        &--mobile{
            @include mobile{
                @include flex(flex-end,unset);
            }
        }
    }

    &__policy {
        margin-bottom: 1.6rem;
        text-align: center;

        @include mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            list-style-type: disc;
            margin-bottom: 0;

        }

        &__content {
            display: inline-block;
            color: $textColor;
            @include body1(400);
            padding-inline: 1.5rem;
            border-right: 1px solid $borderColor;
            transition: all .4s ease-in;
            cursor: pointer;

            &:hover {
                color: $primaryColor;
            }

            &:last-child {
                border-right: none;
            }

            @include desktop {
                font-size: 1.4rem;
            }

            @include mobile {
                font-size: 1.6rem;
                padding-inline: 0rem;
                display: revert;
                margin-bottom: 3rem;
                border-right: none;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            @include tablet {
                font-size: 1.4rem;
                padding-inline: 1.2rem;
            }
        }
    }

    &__license {
        &--mobile{
            background-color: $mainColor;
            padding: 3.3rem 1.6rem;
            display: none;
            @include mobile{
                display: flex;
            }
        }
        &__container {
            text-align: center;

            @include mobile {
                display: none;
            }
        }

        color: $textColor;
        @include body1(400);

        @include desktop {
            font-size: 1.4rem;
        }

        @include tablet {
            font-size: 1.4rem;
        }

        @include mobile {
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
            &--bottom{
                opacity: .7;
            }
        }
    }
}