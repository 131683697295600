
$mobile-width: 575.98px;
$tablet-width: 991.98px;
$desktop-width: 1024.98px;
$large-desktop-width: 1281px;


$primaryColor: #ED1B24;
$textColor: #FFF;
$subColor: #F3F6FC;
$hover: #CCDCF0;
$borderColor: #E2E7F1;


$fontSizeTitleSmallLap: 45px;
$fontSizeMobile: 25px;
$fontSizeTablet: 40px;


$primaryColor: #ED1B24 !default;
$primaryHoverColor: #ED1B24!default;

$mainColor: #000 !default;
$grayColor: #646464 !default;


$ff-primary: 'VL Bebas Neue';
$ff-secondary: 'Helvetica Neue';



// padding
$pd-x-default: 4.4rem;
$pd-mb-x-default: 1.6rem;
$pd-tl-x-default: 2rem;

// font-size
// h
$fs-h1: 5.2rem;
$fs-h2: 4.3rem;
$fs-h3: 3.4rem;
$fs-h4: 2.8rem;
$fs-h5: 2.4rem;
// title
$fs-title: 1.8rem;
// body
$fs-body1: 1.6rem;
$fs-body2: 1.2rem;
// caption
$fs-caption: 0.9rem;

// header
$heightHeader: 76px;
