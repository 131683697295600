@import "../../../../App.scss";

.recruitment{
    height: 100%;
    width: 100%;
    &__divider{
        width: 100%;
        height: 1px;
        background-color: $borderColor;
    }
    &__container{
        margin: 0rem 17.6rem 8rem;
        @include mobile{
            margin: 0rem 1.6rem;
        }
        @include tablet{
            margin: 0rem 2.6rem;
        }
        @include desktop{
            margin-inline: 4.4rem;
        }
    }
    &__filter{
        &__container{
            margin-block: 3rem;
        }
        &__text{
            @include body1(400);
            color: $mainColor;
            margin-top: 3rem;
        }
        &__clear{
            @include body1(700);
            color: $primaryColor;
            cursor: pointer;
        }
        &__input{
            &__container{
                padding: 1.8rem 2rem;
                border: 1px solid $borderColor;
                margin-top: 2rem;
            }
            &__icon{
                width: 2rem;
                height: 2rem;
            }
            &__text-search{
                @include body1(400);
                color: $mainColor;
                margin-left: 1rem;
                border: none;
                outline: none;
                flex: 1;
            }
        }
    }
    &__collapse-filter{
        &__container{
            margin-block: 3rem;
            & .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content {
                    height: 25rem;
                    overflow: auto;
                }
        }
    }

}