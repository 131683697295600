@import "./sass/index.scss";
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    // Config 1rem = 10px
    font-size: 62.5%;
    @include tablet {
        font-size: 80%;
    }   
}

body {
    scroll-behavior: smooth;
    /* Enables smooth scrolling */
}


button,
input {
    outline: 0;
}
.App{
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.app-bg{
    background-color: $mainColor;
}

/* App.css */
.App {
    transition: filter 1s ease;
    filter: brightness(100%);
}

.App.visible {
    filter: blur(5px);
}

.App.hidden {
    display: none;
}


.bright {
    filter: unset;
}

.dim {
    filter: brightness(0%);
}

.required{
    color: #ED1B24;
    margin-left: .5rem;
}

// @keyframes slideRight {
//     0% {
//         transform: translateX(10rem);
//     }

//     100% {
//         transform: translateX(0);
//     }
// }



::-webkit-scrollbar {
    width: 5px; 
    background: black;
  }

  ::-webkit-scrollbar-thumb {
    background: $primaryColor;
    border-radius: 8px; 
    height: 10px;
  }

  ::-webkit-scrollbar:hover {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primaryColor;
  }
  
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #40a9ff;
    }
}


.ant-tabs-nav-operations {
    display: none !important;
}

.app__contact{
    background: #000;
}