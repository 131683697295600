@import "../../../../../App.scss";
.procedure {
    width: 100%;
    // padding: 0 44px;
    img {
        width: 100%;
        object-fit: cover;
    }
    &__imgCustom{
        @include mobile(){
            height: 426px;
        }
    }
    &__title {
        max-width: 750px;
        margin: 0 auto;
        font-size: 100px;
        font-family: IBM Plex Serif;
        font-style: italic;
        font-weight: 400;
        line-height: 120%;
        margin-top: -65px;
        text-transform: uppercase;
            @include mobile(){
                font-size: 4.8333rem;
                margin-top: -3rem;
                padding: 0 2.4rem;
            }     
       &__content{
        &--top{
            font-family: IBM Plex Serif;
            font-style: italic;
            text-transform: uppercase;
            color: $textColor;
        }
        &--bottom{
            font-family: IBM Plex Serif;
            font-style: italic;
            text-align: right;
            text-transform: uppercase;
            color: $textColor;
        }
       }
    }
    &__desc{
        p{
            background: rgba(255, 255, 255, 0.10);
            padding: 40px;
        }
        
       
    }
    &__content{
        max-width: 1032px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__footer{
        height: 100%;
        padding-bottom: 8rem;
        @include mobile(){
            padding-bottom: 6rem;
        }
        img{
            height: 100%;
        }
        &--left{
            &--img{
                margin-bottom: 30px;
                @include mobile(){
                    margin-bottom: 1.2rem;
                }
            }
        }
    }
}
