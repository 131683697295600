@import "../../../../../App.scss";
.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    &__title {
        color: $textColor;
        font-family: Helvetica Neue;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        padding: 100px 0 10px 0;
    }
    &__desc {
        color: $textColor;
        font-family: VL Bebas Neue;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
    }
    &__time {
        color: $textColor;
        font-family: Helvetica Neue;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        padding-bottom: 100px;
    }
    &__button {
        height: 54px;
        padding: 0 77px;
        margin: auto;
        opacity: 0;
        &:hover {
            color: $primaryColor;
            border-color: $primaryColor;
        }
        &:active {
            color: $primaryColor;
            border-color: $primaryColor;
        }
        &:focus {
            color: $primaryColor;
            border-color: $primaryColor;
        }
        &__mobile {
            opacity: unset;
        }
        &--title {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }

    &__modal {
        width: 100% !important;
        padding: 6.8rem 17.6rem 6.4rem;
        @include tablet() {
            padding: 3.2rem;
        }
        @include mobile() {
            padding: 0;
            max-width: calc(100vw - 0px);
        }
        & .ant-modal-close{
            top: -1rem;
        }
        &__img {
            padding-bottom: 30px;
            height: 520px;
            object-fit: cover;
            @include mobile() {
                height: 40rem;
            }
        }
        img {
            width: 100%;
            object-fit: cover;
            // height: 520px;
        }
        .ant-modal-header {
            background: $mainColor;
            border: none;
            padding: 0 0 3.7rem 0;
            // padding: 16px 0px;
            .ant-modal-close {
                color: $mainColor;
                svg {
                    width: 3.2rem;
                    height: 3.2rem;
                }
        
            }
            .ant-modal-title {
                color: $textColor;
            }
        }
        .ant-modal-content {
            background: $mainColor;
            .ant-modal-close {
                color: $textColor;
                &-icon{
                    width: 100%;
                    height: 100%;
                    justify-content: flex-end;
                }
            }
        }
        .ant-modal-body {
            padding: 0;
        }
        &__tabs {
            padding-bottom: 30px;
            // border-bottom: 1px solid rgba(226, 231, 241, 0.5);
            .scroll__custom {
                cursor: pointer;
                display: flex;
                list-style: none;
                overflow-x: scroll;
                padding: 0 0 3rem 0;
                // margin: 0 auto;
                gap: 30px;
                &__item {
                    cursor: pointer;
                    flex: 0 0 190px;
                    height: 13.5rem;
                    :last-of-type {
                        margin: 0;
                    }
                    &--img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            ::-webkit-scrollbar {
                height: 4px;
                background: #e2e7f1;
                -webkit-border-radius: 1ex;
            }

            ::-webkit-scrollbar-thumb {
                background: $primaryColor;
                -webkit-border-radius: 1ex;
            }

            ::-webkit-scrollbar-corner {
                background: #fff3;
            }
            .no__scroll{
                overflow-x: unset;
            }
        }
        // &__carousel {
        // }
    }
}

.ant-modal-mask {
    background-color: $mainColor !important;
    // opacity: 0.8;
}

.ant-carousel {
    width: 100% !important;
}
