.containerCustom {
  padding-right: 44px;
  padding-left: 44px;
  @include mobile {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.sectionContainer {
  margin-top: 8rem;
  padding-bottom: 8rem;
  @include mobile {
    margin-top: 4rem;
    padding-bottom: 4rem;
  }
}


.subTitle {
  color: $textColor;
  font-family: Helvetica Neue;
  font-size: 1.6rem;
  // font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  opacity: 0.6;
}

.title {
  color: $textColor;
  font-size: 10.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @include mobile(){
    font-size: 6rem;
  }
  @include tablet(){
    font-size: 7.2rem;
  }
}