@font-face {
    font-family: 'VL Bebas Neue';
    src: url('../../assets/fonts/Fonts-2/VLBEBASNEUE/VLBEBASNEUEBOLD.OTF');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'VL Bebas Neue';
    src: url('../../assets/fonts/Fonts-2/VLBEBASNEUE/VLBEBASNEUELIGHT.OTF');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica Neue';
    src: url('../../assets/fonts/Fonts-2/HelveticaNeue/helvetica-neue-light.ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../../assets/fonts/Fonts-2/HelveticaNeue/helvetica-neue-medium.ttf');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

