@import '../../../App.scss';

.form-contact {
    margin-left: 4.5rem;

    @include desktop {
        margin-left: 0;
        margin-top: 0rem;
    }

    @include tablet {
        margin-left: 0;
        margin-top: 8rem;
    }

    @include mobile {
        margin-left: 0;
        margin-top: 8rem;
    }

    &__container {
        padding-inline: 3rem;
    }

    &__title {
        color: $mainColor;
        @include titleBold();
        margin-bottom: 3rem;
    }

    &__label {
        color: $textColor;
        opacity: .7;
        @include body1(400);
        margin-bottom: 1.2rem;
    }

    &__input {
        padding: 1.4rem 1.9rem;
        background-color: inherit;
        color: $textColor;
        &--page{
            font-size: 2.4rem;
        }
        &.ant-input-status-error{
        background-color: inherit !important;
        }

        &__wrapper {
            // margin-bottom: 2rem;
        }
    }

    &__submit {
        width: 100%;
        height: 5.4rem;
        background-color: $mainColor;
        color: $textColor;
        @include flex(space-between, center);
        padding: 3rem 1.9rem;
        box-shadow: 0 0 0 1.4em transparent;
        &:focus{
            background: inherit;
            & svg path{
                stroke: #40a9ff;
            }
        }
        &:hover{
            animation: pulse 1s;
            background: inherit;
            border-color: #40a9ff;
            color: #40a9ff;
            font-weight: 700;
            & svg path{
                stroke: #40a9ff;
            }
        }
        &:hover span{
            font-weight: 700;
        }
        & span{
            transition: .1s;
            @include body1(400);
            @include tablet{
                font-size: 1.4rem;
            }
        }

    }

    &__upload {
        &__wrapper {
            width: 100%;

            & .ant-upload.ant-upload-select {
                width: 100%;
            }@import '../../../App.scss';
            
            .form-profile {
                margin-left: 4.5rem;
            
                @include desktop {
                    margin-left: 0;
                    margin-top: 6rem;
                }
            
                @include tablet {
                    margin-left: 0;
                    margin-top: 8rem;
                }
            
                @include mobile {
                    margin-left: 0;
                    margin-top: 8rem;
                }
            
                &__container {
                    padding: 3rem;
                    border: 1px solid $borderColor;
                }
            
                &__title {
                    color: $mainColor;
                    @include titleBold();
                    margin-bottom: 3rem;
                }
            
                &__label {
                    color: $mainColor;
                    @include body1(400);
                    margin-bottom: 1.2rem;
                }
            
                &__input {
                    padding: 1.7rem 1.9rem;
            
                    &__wrapper {
                        // margin-bottom: 2rem;
                    }
                }
            
                &__submit {
                    width: 100%;
                    height: 5.4rem;
                    background-color: $mainColor;
                    color: $textColor;
            
                }
            
                &__upload {
                    &__wrapper {
                        width: 100%;
            
                        & .ant-upload.ant-upload-select {
                            width: 100%;
                        }
                    }
            
                    width: 100%;
                    height: 5.4rem;
                    @include flex(space-between, center);
                    background-color: $borderColor;
            
                    & svg path {
                        transition: .3s linear;
                    }
            
                    &:hover {
                        & svg path {
                            fill: #40a9ff;
                        }
                    }
                }
            }
        }

        width: 100%;
        height: 5.4rem;
        @include flex(space-between, center);
        background-color: $borderColor;

        & svg path {
            transition: .3s linear;
        }

        &:hover {
            & svg path {
                fill: #40a9ff;
            }
        }
    }
}