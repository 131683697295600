@import "../../../../App.scss";
.serviceTabs {
    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // padding-right: 176px;
        max-width: 48.3rem;
        margin: 0 auto;
        &--text{
            @include mobile(){
                width: 100px;
            }
        }
    }
    &__select {
        margin: 0 3rem 0 4.2rem;
        // height: 100%;
        @include mobile(){
            margin: 0;
        }
        .ant-select-selector {
            background: none !important;
            border: 1px solid $borderColor !important;
            border-radius: 4rem !important;
            min-height: 5.4rem;
            padding: 0 !important;
            opacity: 0.5;
            .ant-select-selection {
                &-item {
                    display: flex;
                    align-items: center;
                    padding: 0 2rem;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    color: $textColor;
                    background: fixed;
                    border: none;
                }
            }
        }
        .ant-select-arrow{
            color: $textColor;
            padding-right: 2rem;
        }
    }
    &__content{
        width: 100%;
        
        
        &__row{
            padding-bottom: 3rem;
            @include mobile(){
                padding-bottom: 0;
                row-gap: 0 !important;
            }
        }
        &__col{
            @include mobile(){
                padding: 0 !important;
                margin-bottom: 2rem;
            }
            &:hover .content__button {
               opacity: 1;
            }
            &:hover img{
                opacity: 0.3;
            }
        }
        img{
            position: relative;
            width: 100%;
            max-height: 64rem;
            object-fit: cover;
            opacity: 0.4;
            min-height: 64rem;
        }
    }
}
