@import "../../../../../App.scss";

.menu-header {
  padding: 0 !important;
  height: 0px;
  background-color: $mainColor;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: calc(76px + 36px);
  left: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-40px);
  transition: all 1s;

  &.active {
    height: calc(100vh - 76px - 36px);
    transform: translateY(-1px);
    opacity: 1;
    @include tablet {
        height: calc(100vh - 76px);
    
        transform: translateY(-40px);
      }
    @include mobile {
      height: calc(100vh - 76px);

      transform: translateY(-40px);
    }
  }

  &__content {
    margin-left: 44px;
    justify-content: unset;
    @include desktop {
      margin-left: 2rem;
    }
        @include tablet {
          margin-left: 0px;
        }
    @include mobile {
      margin-left: 0px;
    }
    & > h2 {
      color: $textColor;
      font-weight: 300;
      line-height: 120%;
      margin-bottom: 60px;
      cursor: pointer;
      transition: all 0.3s linear;

      &.active,
      &:hover,
      & a:hover {
        color: $primaryColor;
      }

      &.active {
        color: $primaryColor;
        font-weight: 700;
      }
    }
    &__language {
      color: $textColor;
      font-family: Helvetica Neue;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      & > svg {
        margin-left: 10px;
      }
    }

    &__service {
      margin-left: calc(177px - 44px);
      font-weight: 700 !important;
      line-height: 120%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include tablet {
          margin-left: 0px;
      
          svg {
            margin-left: 40px;
      
            & path {
              color: $primaryColor
            }
          }
        }
      @include mobile {
        margin-left: 0px;
        svg {
          margin-left: 40px;
          & path {
            color:$textColor;
          }
        }
      }
      &.active-dropdown {
        margin-bottom:0px;
        svg {
          & path {
            fill:$textColor;
          }
        }
      }
      &::before {
        content: "";
        color: white;
        height: 1px;
        width: 150px;
        position: absolute;
        background-color: red;
        border: 1px solid red;
        top: 25px;
        left: -177px;
        @include tablet {
            width: 99px;
            left: 0px;
          }
        @include mobile {
          width: 99px;
          left: 0px;
        }
      }
    }
    .content-service-wrapper {
      margin-top: 40px;
      margin-bottom: 60px;
      display: none;
      .content-service-item {
        color: $textColor;
        font-family: VL Bebas Neue;
        font-size: 34px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
        margin-bottom: 20px;
      }
      &.active-dropdown {
      display: block;
        
      }
    }
  }

  &__container {
    padding: 80px 0;
    @include tablet {
        text-align: center;
        padding: 60px 0;
      }
    @include mobile {
      text-align: center;
      padding: 60px 0;
    }
    .image-wrapper {
      height: 100%;
      width: 73.31vw;
      float: right;
      @include tablet {
          display: none;
        }
      @include mobile {
        display: none;
      }
      .img-item {
        display: block;
        width: 100%;
        // height: 640px;
        position: relative;
        cursor: pointer;

        & > img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          & h3.img-item__title {
            color: $primaryColor;
          }
        }
        &.active h3.img-item__title {
          color: $primaryColor;
        }
        &:hover .img-item__modal{
          background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 120.54%);
        }
        &__modal {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.8;
          background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 60.54%);
          transition: all 1.5s linear;

          
        }

        &__title {
          position: absolute;
          bottom: 9rem;
          left: 40px;
          color: $textColor;
          font-weight: 700;
          line-height: 120%;
          transition: 0.3s linear;
          @include desktop{
            bottom: 1rem;
          }
        }
      }
    }
  }
}
