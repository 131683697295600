@import "../../../../App.scss";



.header {
  position: fixed!important;
  top: 0;
  left: 0;
  padding-left:  $pd-x-default;
  padding-right:  $pd-x-default;
  height: 76px;
  background-color: $mainColor;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
  transition: all 1s;
  @include tablet {
      padding: 12px 16px;
    }
  @include mobile {
    padding: 12px 16px;
  }
  &--first-home{
    height: 5.6rem;
    background-color: rgba(255,255,255,0);
    margin-top: 5.6rem;
    @include mobile {
      margin-top: 0;
    }
  }
  &.active{
    padding: 36px $pd-x-default!important;
    height: calc(76px + 36px);

    @include tablet {
        padding: 12px 16px !important;
        height: 76px;
      }

    @include mobile {
    padding: 12px 16px !important;
    height: 76px;
    }
  }
  &__logo {
    & img{
      transition: all 1s;
      height: 4rem;
      @include mobile {
        height: 3.2rem;
      }
    }
    cursor: pointer;
    &--first img{
      height: 5.6rem;
      @include mobile {
        height: 3.2rem;
      }
    }

  }
  &__tool {
    text-align: center;
    cursor: pointer;

    @include tablet {
        order: 1;
    
        svg {
          width: 36px;
          height: 36px;
          margin-top: 10px;
        }
    
      }

    @include mobile {
    order:1;

      svg {
        width: 36px;
      height: 36px;
      margin-top:10px;
      }
      
    }
  }

  &__search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    
    .search-wrapper {
      width: 0px;
      height: 25px;
      position: relative;
      opacity: 0;
      transition: all 1s ease;

      &__button {
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        color: $textColor;
        &::placeholder {
          color: $textColor;
        }
      }
      &__line {
        width: 100%;
        height: 1px;
        background-color: $borderColor;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &.active {
        width: 246px;
        opacity: 1;
        transition: all 1s ease;
      }
    }
    .search-icon {
      height: 25px;
      vertical-align: bottom;
      svg {
        margin-left: 20px;
      }
    }
    .language {
      display: flex;
      align-items: center;
      color: $textColor;
      & .ant-space-item{
        display: flex;
        font-size: 1.8rem;
      }
      svg {
        margin-left: 10px;

      }
    }
  }
}
