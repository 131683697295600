@import "../helpers/variables";

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none !important;
    }
}

ul,
ol {
    list-style: none;
}

p {
    margin: 0;
}

.click-able {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

b,
strong {
    font-weight: bold !important;
}

.flex-column {
    flex-direction: column;
    display: flex;
}

h1{
    font-size: $fs-h1;
    font-family: $ff-primary;
}

h2 {
    font-size: $fs-h2;
    font-family: $ff-primary;
}

h3 {
    font-size: $fs-h3;
    font-family: $ff-primary;
}

h4 {
    font-size: $fs-h4;
    font-family: $ff-primary;
}

h5 {
    font-size: $fs-h5;
    font-family: $ff-primary;
}

p {
    font-size: fs-body1;
    font-family: $ff-secondary;
    font-style: normal;
    font-weight: 400;
}