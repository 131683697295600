@import './_variables';

@mixin flex($justify-content, $align-item) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin body1($font-weight) {
    font-family: $ff-secondary;
    font-size: $fs-body1;
    font-style: normal;
    font-weight: $font-weight;
    line-height: 120%;
}

@mixin body2() {
    font-family: $ff-secondary;
    font-size: $fs-body2;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

@mixin titleBold(){
  font-family: $ff-secondary;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 21.6px */
}

@mixin tablet {
  @media only screen and (min-width: $mobile-width) and (max-width: $tablet-width) {
    @content;
  }
}

@mixin mobile {
  @media only screen  and (max-width: $mobile-width) {
    @content;
  }
}

// @mixin desktop {
//   @media only screen and (max-width: $desktop-width) {
//     @content;
//   }
// }

@mixin desktop {
  @media only screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
    @content;
  }
}

@mixin large-desktop {
  @media only screen and (min-width: $desktop-width) and (max-width: $large-desktop-width) {
    @content;
  }
}

@mixin custom-breakpoint($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}
