@import '../../../App.scss';

.form-profile{
    margin-left: 4.5rem;
    @include desktop{
        margin-left: 0;
        margin-top: 6rem;
    }
    @include tablet{
        margin-left: 0;
        margin-top: 8rem;
    }
    @include mobile{
        margin-left: 0;
        margin-top: 4rem;
        margin-bottom: 6rem;
    }
    &__container{
        padding: 3rem;
        border: 1px solid $borderColor;
        @include mobile{
            padding: 0;
            border: none;
        }
    }
    &__title{
        color: $mainColor;
        @include titleBold();
        margin-bottom: 3rem;
    }
    &__label{
        color: $mainColor;
        @include body1(400);
        margin-bottom: 1.2rem;
    }
    &__input{
        padding: 1.7rem 1.9rem;
        font-size: 1.6rem;
        
        &__wrapper{
            // margin-bottom: 2rem;
        }
    }

    &__submit{
        width: 100%;
        height: 5.4rem;
        background-color: $mainColor;
        color: $textColor;

    }
    &__upload{
        &__wrapper{
            width: 100%;
        & .ant-upload.ant-upload-select{
            width: 100%;
        }
        }
        
        width: 100%;
        height: 5.4rem;
        @include flex(space-between,center);
        background-color: $borderColor;
        & svg path{
            transition: .3s linear;
        }
        &:hover{
            & svg path{
                fill: #40a9ff;
            }
        }
    }
}