@import "../../App.scss";

.homepage {
    color: red;

    @include mobile() {
        color: green;
    }

    &__up {
        position: absolute;
        top: 20%;
        right: 6%;
        height: 50px;
        width: 50px;
        /* background: #fff; */
        z-index: 1;
    }
}