@import "../../App.scss";

.tabService {
    .ant-tabs {
        &-ink-bar {
            background: $primaryColor;
            height: 4px !important;
            border-radius: 2px;

        }

        &-nav {
            margin-inline: 17.6rem;
            margin-bottom: 8rem;

            @include tablet() {
                margin-inline: 3.6rem;
            }

            @include desktop {
                margin-inline: 4.6rem;
            }

            @include mobile() {
                margin-inline: 0;
                margin-bottom: 4rem;
            }


            &::before {
                border-bottom: none;
            }

            &-list {
                width: 100%;
                border-bottom: 1px solid rgba(226, 231, 241, 0.5);

                // margin: 0 176px;
                @include mobile() {
                    margin: 0;

                }
            }
        }

        &-tab {
            // width: 432px;
            flex: 1;
            justify-content: center;
            margin: 0;

            // padding: 2.2rem 0;
            @include mobile() {
                width: 100%;
                padding: 22px;
            }

            &-btn {
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                color: $textColor;
                opacity: 0.6;
            }

            &:active {
                .ant-tabs-tab-btn {
                    color: $primaryColor;

                }
            }
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: $primaryColor;
        font-weight: 700;
        opacity: unset;
    }

    &__primary {
        .ant-tabs-tab-btn {
            color: $mainColor;

            @include mobile() {
                white-space: pre-line;
                
            }
        }

        
    }
}

#rc-tabs-0-tab-32{
    white-space: unset;
}